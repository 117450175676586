<template>
  <div class="listing__items listing__container">
    <div class="listing__items-title-result">368 отличных вариантов для вас</div>
    <div class="list-item-wrap" v-for="(item, index) in fakeListItems">
      <div class="list-item">
        <div class="list-item-img"><img :src="`${item.img}`" alt=""></div>
        <div class="list-item__header">
          <div class="list-item__title">{{ item.title }}</div>
          <div class="list-item__kolvo">{{ item.counts }}</div>
        </div>
      </div><!--end list-item -->
    </div><!--end list-item-wrap -->

  </div>
</template>
<style lang="scss" scoped>
.listing__items {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  grid: none;
  grid-gap: unset;
  grid-template: none;
  overflow: auto;
  height: auto;
  padding: 0 0 10px;
  
  &-title-result {
    color: #fff;
    font-size: 40px;
    background: #D96438;
    padding: 25px 35px;
    width: 100%;
    @media(max-width: 900px){
      font-size: 21px;
    }
  }
}

.listing--blur {
  filter: blur(3px)
}

.listing--blur2 {
  filter: blur(2px)
}

.list-item {
  padding: 0;
  color: #fff;
  font-size: 14px;
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  background: #3F3F3F;
  &-wrap {
    width: 50%;
    padding: 10px;
    @media(max-width: 750px) {
      width: 100%;
      padding: 10px 0;
    }
  }
  &-img {
    img {
      max-width: 100%;
    }
  }
  &__header {
    padding-bottom: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 36px;
    @media(max-width: 900px){
      font-size: 22px;
    }
  }
  
  &__title {
    padding-bottom: 4px;
    overflow: hidden;
    position: relative;
    max-width: 50%;
  }
  
  &__kolvo {
    padding-bottom: 2px;
    color: #D96438;
  }
  
  &__footer {
    border-top: 1px solid #E6E6E6;
    padding-top: 16px;
    font-size: 16px;
    
  }
  
  &__price {
    font-weight: 700;
  }
  
  &__counts {
  
  }
  
  .list-item__go {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;
    
    li {
      display: flex;
      margin-right: 3px;
      align-items: center;
    }
    
    .metro-icon {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
      vertical-align: top;
      margin-right: 8px;
      position: relative;
      top: -2px;
    }
    
    svg {
      position: relative;
      top: -2px;
    }
  }
}
</style>
<script>
import {Fragment} from 'vue-fragment'

export default {
  //props: ,
  components: {
    'Fragment': Fragment
  },
  data() {
    return {}
  },
  computed: {
    fakeListItems() {
      let fakeResult;
  
      if(document.body.getAttribute('data-region') === 'spb'){
        fakeResult = [
          {
            title: "Даниловский",
            img: "/images/quiz/list-item1.png",
            addr: "Санкт-Петербург, Красногвардейский",
            metro: "Академическая",
            goType: "car",
            time: "20 мин",
            price: "от 3.8 до 11.1 млн руб",
            counts: "56 квартир",
          },
          {
            title: "в лесу",
            img: "/images/quiz/list-item2.png",
            addr: "Санкт-Петербург, Красногвардейский",
            metro: "Академическая",
            goType: "car",
            time: "20 мин",
            price: "от 3.8 до 11.1 млн руб",
            counts: "27 квартир",
          },
          {
            title: "покровский",
            img: "/images/quiz/list-item3.png",
            addr: "Санкт-Петербург, Красногвардейский",
            metro: "Академическая",
            goType: "car",
            time: "20 мин",
            price: "от 3.8 до 11.1 млн руб",
            counts: "11 квартир",
          },
          {
            title: "нагорный",
            img: "/images/quiz/list-item4.png",
            addr: "Санкт-Петербург, Красногвардейский",
            metro: "Академическая",
            goType: "car",
            time: "20 мин",
            price: "от 3.8 до 11.1 млн руб",
            counts: "9 квартир",
          },
          
        ]
      }else {
        fakeResult = [
          {
            title: "Даниловский",
            img: "/images/quiz/list-item1.png",
            addr: "Санкт-Петербург, Красногвардейский",
            metro: "Академическая",
            goType: "car",
            time: "20 мин",
            price: "от 3.8 до 11.1 млн руб",
            counts: "56 квартир",
          },
          {
            title: "в лесу",
            img: "/images/quiz/list-item2.png",
            addr: "Санкт-Петербург, Красногвардейский",
            metro: "Академическая",
            goType: "car",
            time: "20 мин",
            price: "от 3.8 до 11.1 млн руб",
            counts: "27 квартир",
          },
          {
            title: "покровский",
            img: "/images/quiz/list-item3.png",
            addr: "Санкт-Петербург, Красногвардейский",
            metro: "Академическая",
            goType: "car",
            time: "20 мин",
            price: "от 3.8 до 11.1 млн руб",
            counts: "11 квартир",
          },
          {
            title: "нагорный",
            img: "/images/quiz/list-item4.png",
            addr: "Санкт-Петербург, Красногвардейский",
            metro: "Академическая",
            goType: "car",
            time: "20 мин",
            price: "от 3.8 до 11.1 млн руб",
            counts: "9 квартир",
          },
          
        ]
      }
      
      
      
      return fakeResult;
    }
  }
}
</script>
